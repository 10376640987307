<template>
  <div>
    <div class="search-bar flex justify-between items-center">
      <a-form-model layout="inline" class="flex-1"
                    :model="searchForm"
                    :label-col="labelCol"
                    :wrapper-col="wrapperCol">
        <a-form-model-item label="收银系统" prop="offline_cash_register_id">
          <a-select placeholder="收银系统" style="width:180px;"
                    allowClear
                    v-model="searchForm.offline_cash_register_id">
            <a-select-option v-for="(item, index) in cashierList"
                    :key="index+1" >{{ item.name }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item style="width:270px;" prop="outlets_id" label="网点">
          <a-select show-search allowClear
                    placeholder="请输入网点名"
                    :default-active-first-option="false"
                    :show-arrow="false"
                    :filter-option="false"
                    :not-found-content="null"
                    @search="searchOutlets"
                    v-model="searchForm.outlets_id">
            <a-select-option v-for="(item, index) in outletsList"
                             :key="index"
                             :value="item.outlets_id">{{item.outlets_name}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item prop="order_no" label="订单号">
          <a-input placeholder="输入订单号"
                  allowClear 
                  v-model="searchForm.order_no"></a-input>
        </a-form-model-item>
      </a-form-model>
      <div>
        <!-- <a-button @click="resetForm">重置</a-button> -->
        <a-button class="ml-2" type="primary" :loading="isLoading" @click="handlerSearch">搜索</a-button>
      </div>
    </div>

    <div class="flex items-center mt-2 mb-2">
      <span class="text-lg font-bold p-3">推送记录</span>
    </div>

    <base-table
        :columnsData="columns"
        :tableData="tableData"
        :total="total"
        :getListFunc="initData"
        :page.sync="searchForm.page"
        :pageSize.sync="searchForm.page_count">
      <template #operation="{record}">
        <a-tooltip placement="topLeft" :title="record['return_data']">
          <!-- <a-button type="link">查看返回</a-button> -->
          <a-tag v-if="record.push_status=='推送失败'" color="red">{{record.push_status}}</a-tag>
          <a-tag v-else-if="record.push_status=='推送成功'" color="green">{{record.push_status}}</a-tag>
          <a-tag v-else color="blue">{{record.push_status}}</a-tag>
        </a-tooltip>
        <a-button v-if="record.push_status=='推送失败' || record.push_status=='未推送'" 
              class="ml-2 p-0" type="link" @click="retryPush(record)">重推订单</a-button>
      </template>
    </base-table>
      
  </div>
</template>

<script>
import {
  getList as getCashierList,
  getPushList,
  pushRetry
} from "@/api/system/cashier.js"
import { getOutletsList } from '@/api/customer'

export default {
  components: {

  },
  data() {
    return {
      wrapperCol: { span: 18 },
      labelCol: { span: 6 },

      columns: [
        {
          title: 'id',
          dataIndex: 'id',
          align: 'center',
          width: 80,
        },{
          title: '收银软件',
          dataIndex: 'offline_cash_register_name',
          align: 'center',
          width: 90,
        },{
          title: '订单号',
          dataIndex: 'order_no',
          align: 'center',
          width: 160,
        },{
          title: '订单网点',
          dataIndex: 'outlets_name',
          align: 'center',
          width: 100,
        },{
          title: '订单状态',
          dataIndex: 'order_status',
          align: 'center',
          width: 80,
        },{
          title: '添加时间',
          dataIndex: 'add_time',
          align: 'center',
          width: 180,
        },{
          title: '完成时间',
          dataIndex: 'finish_push_time',
          align: 'center',
          width: 180,
        },{
        //   title: '推送状态',
        //   dataIndex: 'push_status',
        //   align: 'center',
        //   width: 80,
        //   slots: { customRender: "push_status" },
        // },{
          title: "返回",
          dataIndex: "operation",
          align: "center",
          width: 140,
          slots: { customRender: "operation" },
        },
      ],

      cashierList: [],
      outletsList: [],

      searchForm: {
        offline_cash_register_id: undefined,
        outlets_id: undefined,
        order_no: "",
        page: 1,
        page_count: 40,
      },
      total: 0,
      tableData: [],

      isLoading: false,
    };
  },
  async mounted() {
    const { code, data } = await getCashierList()
    if (code == 0) {
      this.cashierList = data.list
    }
  },
  methods: {
    handlerSearch(){
      if(this.isLoading) return

      this.searchForm.page = 1
      this.initData();
    },
    resetForm() {
      this.$refs.searchRef.resetFields();
      // this.initData();
    },
    async initData(){
      this.isLoading = true
      const { data, code } = await getPushList(this.searchForm);
      this.isLoading = false
      if (code === 0) {
        this.tableData = data.list;
        this.total = data.total_count
      }
    },

    async retryPush(record){
      const { code } = await pushRetry({
        order_no: record.order_no,
      })
      if (code === 0) {
        this.initData()
      }
    },

    searchOutlets(value){
      if(this.timer){
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(()=>{
        this.initOutletsList(value)
      }, 300)
    },
    async initOutletsList (value) {
      const params = {
        outlets_name: value,
      }
      if (value) {
        const { data, code } = await getOutletsList(params)
        if (code === 0) this.outletsList = data.list
      }
    },

    
  },
};
</script>

<style lang="less">

</style>
